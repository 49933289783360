<template>
  <CBox
    bg="white"
    minH="100px"
    borderRadius="5px"
    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
  >
    <CBox pt="20px" pb="10px" mx="20px">
      <CText
        v-if="$route.params.track"
        fontFamily="Montserrat"
        fontStyle="normal"
        fontSize="18px"
        fontWeight="700"
        class="pointer"
        @click.native="
          $router.push({
            name: 'Challenge Track',
            params: { track: $route.params.track,slug: currentAppType.slug },
          })
        "

        >{{ currentAppType.name }}</CText
      >
      <CText
        v-else
        fontFamily="Montserrat"
        fontStyle="normal"
        fontSize="18px"
        fontWeight="700"
        class="pointer"
        @click.native="
          $router.push({
            name: 'Competition',
            params: { slug: currentAppType.slug },
          })
        "

        >{{ currentAppType.name }}</CText
      >
      <CBox d="inline-flex" v-if="showTags && activeHashtags.length > 0">
        <CText
          v-for="(tag, i) in activeHashtags"
          :key="i"
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="14px"
          fontWeight="500"
          color="activeMenu"
          mr="1"
        >
          #{{ tag
          }}<span
            v-if="activeHashtags.length > 0 && i < activeHashtags.length - 1"
            >,</span
          >
        </CText>
      </CBox>
    </CBox>
    <CDivider />
    <CBox pb="20px" mx="20px">
      <template v-if="!showStats">
        <CText
          v-if="this.description"
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="14px"
          color="fontMain"
          >{{ this.description }} {{ currentAppType.name }}</CText
        >
        <template v-else>
          <template v-if="useMoreDesc">
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="14px"
              color="fontMain"
              v-html="
                currentAppType.description &&
                currentAppType.description.length > 150 &&
                !showMore
                  ? `${currentAppType.description.slice(0, 150)}...`
                  : currentAppType.description
              "
            >
            </CText>
            <CText
              v-if="currentAppType.description.length > 150"
              @click.native="handleShowMore"
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="14px"
              fontWeight="600"
              cursor="pointer"
              color="activeMenu"
            >
              {{ showMore ? "Show Less" : "Show More" }}
            </CText>
          </template>

          <CText
            v-else
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="14px"
            color="fontMain"
            v-html="currentAppType.description"
          >
          </CText>
        </template>
      </template>
      <template v-if="showStats">
        <CGrid
          :templateColumns="[
            '6fr 6fr',
            '6fr 6fr',
            '2fr 2fr 2fr 2fr 2fr',
            '2fr 2fr 2fr 2fr 2fr',
          ]"
          gap="6"
          v-if="!checkEmpObject(stats)"
        >
          <CBox whiteSpace="0" borderRight="1px solid #EAEAEA" pr="6">
            <CBox d="inline-flex">
              <CImage mr="1" :src="require('@/assets/icon/ic-user.svg')" />
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="16px"
                fontWeight="600"
              >
                {{ stats.total_challengers }}
              </CText>
            </CBox>
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="11px"
              display="block"
            >
              Challenger
            </CText>
          </CBox>
          <CBox
            :borderRight="[
              'none',
              'none',
              '1px solid #EAEAEA',
              '1px solid #EAEAEA',
            ]"
            :pr="['0', '0', '6', '6']"
            :pl="['0', '0', '4', '4']"
          >
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="16px"
              fontWeight="600"
            >
              <CCircularProgress
                :value="Math.round(stats.passed_test_percentage)"
                size="18px"
                :thickness="0.4"
              />
              &nbsp;{{ Math.round(stats.passed_test_percentage) }}%
            </CText>
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="11px"
              mt="2"
              display="block"
            >
              Passed Challenge
            </CText>
          </CBox>
          <CBox
            borderRight="1px solid #EAEAEA"
            :pr="['0', '0', '6', '6']"
            :pl="['0', '0', '4', '4']"
          >
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="16px"
              fontWeight="600"
            >
              <CCircularProgress
                :value="Math.round(stats.perfect_score_percentage)"
                size="18px"
                :thickness="0.4"
                color="green"
              />
              &nbsp;{{ Math.round(stats.perfect_score_percentage) }}%
            </CText>
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="11px"
              mt="2"
              display="block"
            >
              Perfect Score
            </CText>
          </CBox>
          <CBox
            :borderRight="[
              'none',
              'none',
              '1px solid #EAEAEA',
              '1px solid #EAEAEA',
            ]"
            :pr="['0', '0', '6', '6']"
            :pl="['0', '0', '4', '4']"
          >
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="16px"
              fontWeight="600"
            >
              {{ Math.round(stats.best_app_response) / 1000 }}ms
            </CText>
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="11px"
              mt="2"
              display="block"
            >
              Best App Response
            </CText>
          </CBox>
          <CBox :pl="['0', '0', '4', '4']">
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="16px"
              fontWeight="600"
            >
              {{ Math.round(stats.average_app_response) / 1000 }}ms
            </CText>
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="11px"
              mt="2"
              display="block"
            >
              Avg. Response Time
            </CText>
          </CBox>
        </CGrid>
      </template>
    </CBox>
  </CBox>
</template>

<script>
import { CCircularProgress, CBox } from "@chakra-ui/vue";
import _ from "lodash";
export default {
  components: {
    CCircularProgress,
    CBox,
  },
  props: {
    currentAppType: {
      type: Object,
      default() {
        return {};
      },
    },
    description: {
      type: String,
      default: "",
    },
    showStats: {
      type: Boolean,
      default: false,
    },
    stats: {
      type: Object,
      default() {
        return {};
      },
    },
    showTags: {
      type: Boolean,
      default: false,
    },
    useMoreDesc: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMore: false,
    };
  },
  computed: {
    activeHashtags() {
      return this.$store.getters.currentLeaderboardsHashtagActiveFilters;
    },
  },
  methods: {
    checkEmpObject(obj) {
      return _.isEmpty(obj);
    },
    handleShowMore() {
      this.showMore = !this.showMore;
    },
  },
};
</script>
