var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CBox',{attrs:{"bg":"white","minH":"100px","borderRadius":"5px","boxShadow":"0px 4px 10px rgba(0, 0, 0, 0.1)"}},[_c('CBox',{attrs:{"pt":"20px","pb":"10px","mx":"20px"}},[(_vm.$route.params.track)?_c('CText',{staticClass:"pointer",attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"18px","fontWeight":"700"},nativeOn:{"click":function($event){return _vm.$router.push({
          name: 'Challenge Track',
          params: { track: _vm.$route.params.track,slug: _vm.currentAppType.slug },
        })}}},[_vm._v(_vm._s(_vm.currentAppType.name))]):_c('CText',{staticClass:"pointer",attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"18px","fontWeight":"700"},nativeOn:{"click":function($event){return _vm.$router.push({
          name: 'Competition',
          params: { slug: _vm.currentAppType.slug },
        })}}},[_vm._v(_vm._s(_vm.currentAppType.name))]),(_vm.showTags && _vm.activeHashtags.length > 0)?_c('CBox',{attrs:{"d":"inline-flex"}},_vm._l((_vm.activeHashtags),function(tag,i){return _c('CText',{key:i,attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"14px","fontWeight":"500","color":"activeMenu","mr":"1"}},[_vm._v(" #"+_vm._s(tag)),(_vm.activeHashtags.length > 0 && i < _vm.activeHashtags.length - 1)?_c('span',[_vm._v(",")]):_vm._e()])}),1):_vm._e()],1),_c('CDivider'),_c('CBox',{attrs:{"pb":"20px","mx":"20px"}},[(!_vm.showStats)?[(this.description)?_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"14px","color":"fontMain"}},[_vm._v(_vm._s(this.description)+" "+_vm._s(_vm.currentAppType.name))]):[(_vm.useMoreDesc)?[_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"14px","color":"fontMain"},domProps:{"innerHTML":_vm._s(
              _vm.currentAppType.description &&
              _vm.currentAppType.description.length > 150 &&
              !_vm.showMore
                ? `${_vm.currentAppType.description.slice(0, 150)}...`
                : _vm.currentAppType.description
            )}}),(_vm.currentAppType.description.length > 150)?_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"14px","fontWeight":"600","cursor":"pointer","color":"activeMenu"},nativeOn:{"click":function($event){return _vm.handleShowMore.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.showMore ? "Show Less" : "Show More")+" ")]):_vm._e()]:_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"14px","color":"fontMain"},domProps:{"innerHTML":_vm._s(_vm.currentAppType.description)}})]]:_vm._e(),(_vm.showStats)?[(!_vm.checkEmpObject(_vm.stats))?_c('CGrid',{attrs:{"templateColumns":[
          '6fr 6fr',
          '6fr 6fr',
          '2fr 2fr 2fr 2fr 2fr',
          '2fr 2fr 2fr 2fr 2fr',
        ],"gap":"6"}},[_c('CBox',{attrs:{"whiteSpace":"0","borderRight":"1px solid #EAEAEA","pr":"6"}},[_c('CBox',{attrs:{"d":"inline-flex"}},[_c('CImage',{attrs:{"mr":"1","src":require('@/assets/icon/ic-user.svg')}}),_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"16px","fontWeight":"600"}},[_vm._v(" "+_vm._s(_vm.stats.total_challengers)+" ")])],1),_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"11px","display":"block"}},[_vm._v(" Challenger ")])],1),_c('CBox',{attrs:{"borderRight":[
            'none',
            'none',
            '1px solid #EAEAEA',
            '1px solid #EAEAEA',
          ],"pr":['0', '0', '6', '6'],"pl":['0', '0', '4', '4']}},[_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"16px","fontWeight":"600"}},[_c('CCircularProgress',{attrs:{"value":Math.round(_vm.stats.passed_test_percentage),"size":"18px","thickness":0.4}}),_vm._v("  "+_vm._s(Math.round(_vm.stats.passed_test_percentage))+"% ")],1),_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"11px","mt":"2","display":"block"}},[_vm._v(" Passed Challenge ")])],1),_c('CBox',{attrs:{"borderRight":"1px solid #EAEAEA","pr":['0', '0', '6', '6'],"pl":['0', '0', '4', '4']}},[_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"16px","fontWeight":"600"}},[_c('CCircularProgress',{attrs:{"value":Math.round(_vm.stats.perfect_score_percentage),"size":"18px","thickness":0.4,"color":"green"}}),_vm._v("  "+_vm._s(Math.round(_vm.stats.perfect_score_percentage))+"% ")],1),_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"11px","mt":"2","display":"block"}},[_vm._v(" Perfect Score ")])],1),_c('CBox',{attrs:{"borderRight":[
            'none',
            'none',
            '1px solid #EAEAEA',
            '1px solid #EAEAEA',
          ],"pr":['0', '0', '6', '6'],"pl":['0', '0', '4', '4']}},[_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"16px","fontWeight":"600"}},[_vm._v(" "+_vm._s(Math.round(_vm.stats.best_app_response) / 1000)+"ms ")]),_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"11px","mt":"2","display":"block"}},[_vm._v(" Best App Response ")])],1),_c('CBox',{attrs:{"pl":['0', '0', '4', '4']}},[_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"16px","fontWeight":"600"}},[_vm._v(" "+_vm._s(Math.round(_vm.stats.average_app_response) / 1000)+"ms ")]),_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"11px","mt":"2","display":"block"}},[_vm._v(" Avg. Response Time ")])],1)],1):_vm._e()]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }